.vid1 {
  /* background: linear-gradient(117.82deg, #9CECFB -9.12%, #65C7F7 48.59%, #0052D4 106.3%); */
  box-shadow: 20px 20px 0px 0px rgba(245, 245, 245, 1);
  position: absolute;
  z-index: 10;
}

.homepage_bg {
  background: url("../assets/Images/bghome.svg");
}

.border-container {
  position: relative;
  padding: 2px;
  background: linear-gradient(
    45deg,
    rgb(231, 90, 90),
    rgb(249, 162, 0),
    rgb(235, 80, 212),
    rgb(17, 230, 195),
    rgb(135, 206, 235) ,
    rgb(114, 4, 193),
    rgb(227, 97, 136)
  );
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  border-radius: 3xl;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* .border-container > div {
  border-radius: 3xl;
  background: #161d29;
  padding: 10px;
} */
