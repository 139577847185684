.main {
    width: 60vw;
    margin: auto;
    color: white;
    /* border: 2px solid white; */
}

.heading{
    font-size: 30px;
    text-align: center;
    
}
.subHeading{
    font-size: 25px;
    
}

li{
    margin-top: 20px;
}

strong{
    font-size: 20px;
    font-style: italic;
    font-weight: 800;
}

@media  (max-width: 768px) {
    .main{
        width: 95vw;
    }
}