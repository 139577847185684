.main{ 
    padding: 20px;
    width: 80%;
    /* margin: auto; */
    height: auto;
    color: white;
}
.heading {
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 20px;
}

.subheading{
    padding-top: 20px;
    font-size: 20px;
    font-weight: 700;
}

li{
    font-weight: 200;
    /* font-style: italic; */
    font-size: 15px;
}