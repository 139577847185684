  main {
    color: azure;
    /* width: 1024px; */
    width: 75%;
    margin: auto;
    height: auto;
    margin-bottom: 50px;
  }

  .header {
    font-size: 20px;
    font-weight: 800;
    /* font-style: italic; */
    margin-top: 30px;
  }

  .head {
    font-size: 1.2rem;
    font-weight: 800;
    font-family: sans-serif;
    /* font-style: italic; */
  }

  .pre,
  .list {
    font-size: 15px;
    font-weight: 200;
    font-family: monospace;
  }

@media (max-width : 508px){
    main{
        width: 95%;
    }
}

